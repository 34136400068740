import { Injectable } from '@angular/core';
import { StorageProvider } from './storage-provider';

@Injectable({ providedIn: 'root' })
export class SessionStorageProvider implements StorageProvider {
  async set(key: string, value: unknown): Promise<void> {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  async get(key: string): Promise<unknown> {
    const data = sessionStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return data;
  }

  async clear(key: string): Promise<void> {
    sessionStorage.removeItem(key);
  }
}
