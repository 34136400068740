import { Injectable } from '@angular/core';
import { StorageProvider } from './storage-provider';

const DB_NAME = 'storage';

@Injectable({ providedIn: 'root' })
export class IDBStorageProvider implements StorageProvider {
  private db!: IDBDatabase;

  private async openDB(): Promise<IDBDatabase> {
    if (this.db) {
      return this.db; // Datenbank bereits geöffnet, bestehende Verbindung zurückgeben
    }

    return new Promise<IDBDatabase>((resolve, reject) => {
      const request = indexedDB.open('isa-storage', 1);

      request.onerror = (event) => {
        reject(event);
      };

      request.onupgradeneeded = (event) => {
        this.db = (event.target as IDBOpenDBRequest).result;

        if (!this.db.objectStoreNames.contains(DB_NAME)) {
          this.db.createObjectStore(DB_NAME, { keyPath: 'key' });
        }
      };

      request.onsuccess = (event) => {
        this.db = (event.target as IDBOpenDBRequest).result;
        resolve(this.db);
      };
    });
  }

  private async getObjectStore(mode: IDBTransactionMode = 'readonly'): Promise<IDBObjectStore> {
    const db = await this.openDB();
    const transaction = db.transaction(DB_NAME, mode);
    return transaction.objectStore(DB_NAME);
  }

  async set(key: string, value: unknown): Promise<void> {
    const store = await this.getObjectStore('readwrite');
    return new Promise<void>((resolve, reject) => {
      const request = store.put({ key, value });
      request.onsuccess = () => resolve();
      request.onerror = (event) => reject(event);
    });
  }

  async get(key: string): Promise<unknown> {
    const store = await this.getObjectStore();

    return new Promise<unknown>((resolve, reject) => {
      const request = store.get(key);
      request.onsuccess = () => resolve(request.result?.value);
      request.onerror = (event) => reject(event);
    });
  }

  async clear(key: string): Promise<void> {
    const store = await this.getObjectStore('readwrite');

    return new Promise<void>((resolve, reject) => {
      const request = store.delete(key);
      request.onsuccess = () => resolve();
      request.onerror = (event) => reject(event);
    });
  }
}
