import { inject, Injectable } from '@angular/core';
import { StorageProvider } from './storage-provider';
import { UserStateService } from '@generated/swagger/isa-api';
import { firstValueFrom, map, shareReplay } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserStorageProvider implements StorageProvider {
  #userStateService = inject(UserStateService);

  private state$ = this.#userStateService.UserStateGetUserState().pipe(
    map((res) => {
      if (res.result?.content) {
        return JSON.parse(res.result.content);
      }
      return {};
    }),
    shareReplay(1),
  );

  async set(key: string, value: unknown): Promise<void> {
    const current = await firstValueFrom(this.state$);
    firstValueFrom(
      this.#userStateService.UserStateSetUserState({
        content: JSON.stringify({ ...current, [key]: value }),
      }),
    );
  }

  async get(key: string): Promise<unknown> {
    const userState = await firstValueFrom(this.state$);
    return userState[key];
  }

  async clear(key: string): Promise<void> {
     
    const current = await firstValueFrom(this.state$);
    delete current[key];
    firstValueFrom(this.#userStateService.UserStateResetUserState());
  }
}
