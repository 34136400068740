import { Type } from '@angular/core';
import { getState, patchState, signalStoreFeature, withHooks, withMethods } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { StorageProvider } from './storage-provider';
import { injectStorage } from './storage';
import { debounceTime, pipe, switchMap } from 'rxjs';

export function withStorage(storageKey: string, storageProvider: Type<StorageProvider>) {
  return signalStoreFeature(
    withMethods((store, storage = injectStorage(storageProvider)) => ({
      storeState: rxMethod<void>(
        pipe(
          debounceTime(1000),
          switchMap(() => storage.set(storageKey, getState(store))),
        ),
      ),
      restoreState: async () => {
        const data = await storage.get(storageKey);
        if (data) {
          patchState(store, data);
        }
      },
    })),
    withHooks((store) => ({
      onInit() {
        store.restoreState();
      },
    })),
  );
}
