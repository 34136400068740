import { inject, InjectionToken, Type } from '@angular/core';
import { StorageProvider } from './storage-provider';
import { z } from 'zod';
import { OAuthService } from 'angular-oauth2-oidc';
import { hash } from './hash.utils';

export const USER_SUB = new InjectionToken<() => string>('core.storage.user-sub', {
  factory: () => {
    const auth = inject(OAuthService, { optional: true });
    return () => auth?.getIdentityClaims()?.['sub'] ?? 'anonymous';
  },
});

export class Storage {
  private readonly userSub = inject(USER_SUB);

  constructor(private storageProvider: StorageProvider) {}

  private getKey(token: string | object): string {
    const userSub = this.userSub();
    return `${userSub}:${hash(token)}`;
  }

  set<T>(token: string | object, value: T): Promise<void> {
    return this.storageProvider.set(this.getKey(token), value);
  }

  async get<T>(token: string | object, schema?: z.ZodType<T>): Promise<T | unknown> {
    const data = await this.storageProvider.get(this.getKey(token));
    if (schema) {
      return schema.parse(data);
    }
    return data;
  }

  async clear(token: string | object): Promise<void> {
    return this.storageProvider.clear(this.getKey(token));
  }
}

const storageMap = new WeakMap<Type<StorageProvider>, Storage>();

export function injectStorage(storageProvider: Type<StorageProvider>): Storage {
  if (storageMap.has(storageProvider)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return storageMap.get(storageProvider)!;
  }

  const storage = new Storage(inject(storageProvider));
  storageMap.set(storageProvider, storage);
  return storage;
}
